import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/core';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>About</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">About</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <p>
                  Le nom du blog, itinerantur signifie voyage; le terme est à envisager dans tous
                  les sens et sans limites aucunes. The name of the blog, itinerantur means trip;
                  the term is to be considered in every sense and without any limits.
                </p>
                <div style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: '600' }}>
                  <p>***</p>
                  <p>Regarder – Expérimenter – Penser</p>
                  <p>***</p>
                </div>
                <p>
                  Simple carnet de bord de ma vie. La navigation est au choix, par ordre
                  chronologique, par catégories – Regarder rassemblera principalement des posts de
                  photos avec peu ou pas de texte, Penser sera des brides de vie et Expérimenter
                  rassemblera des recettes de cuisine ou d’autres projets manuels – et enfin par
                  tags.
                </p>{' '}
                <p>
                  Logbook of my life. There are many ways of navigation, chronological order,
                  categorys – Regarder will be posts with photos and small or no text, Penser are
                  musings on life, Expérimenter are cooking recipes and any other DIY projects –
                  finally you can simply use tags and be surprised.
                </p>{' '}
                <p>
                  Etant un carnet de bord, je veux être le plus libre possible, des postes seront en
                  français, d’autres en anglais. Les tags peuvent permettre de limiter à une seule
                  langue.
                </p>{' '}
                <p>
                  This is a logbook and I want to be as free as possible, some posts will be in
                  French, some in English – tags can help to find only one language.
                </p>{' '}
                <p>
                  Une dernière chose, si tu ne me connais pas je suis Céline. Française de
                  naissance, britannique par amour et américaine par opportunité. Si tu veux en
                  savoir plus j’ai quelques histoires à partager avec toi…
                </p>{' '}
                <p>
                  One more thing, if you don’t know me I am Céline. French by birth, British by love
                  and American by opportunity. If you want to know more I have a few stories to
                  share with you…
                </p>{' '}
                <blockquote style={{ fontStyle: 'italic' }}>
                  «Souvent, j’ai accompli de délicieux voyages, embarqué sur un mot dans les abîmes
                  du passé, comme l’insecte qui flotte au gré d’un fleuve sur quelque brin d’herbe.»{' '}
                  Balzac
                </blockquote>
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
